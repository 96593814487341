import React, { useState } from 'react';
import { concat, uniqBy } from 'lodash';

import { FormAutocomplete } from 'common/components/input';
import { Paper, Stack } from 'common/components/material';
import { useDebounce } from 'common/hooks';
import { useTheme } from '@mui/material/styles';
import { validateMinLength } from 'common/utils/app.utils';
import { TagSelectorProps } from '../types/tag-selector-props.type';
import { useSearchTags } from '../hooks/useSearchTags';
import { PaperProps } from 'common/components/material/Paper';
import { TAG_INPUT_LABEL, TAG_INPUT_NAME, TAG_PLACEHOLDER } from '../tags.constants';

const CustomPaper = (props: PaperProps) => {
  const { palette } = useTheme();

  return (
    <Paper
      elevation={3}
      {...props}
      sx={{
        backgroundColor: palette.DropdownList.Bg,
        color: palette.DropdownList.Label,
      }}
    />
  );
};

const TagSelector: React.FC<TagSelectorProps> = ({
  formFieldProps,
  readOnly,
  value,
  headerHelperText,
  footerHelperText,
  multiple = true,
  name,
  label,
  contentType,
  disabledIds = [],
  onChange,
  ...tagSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue);

  const { data: tags, isLoading } = useSearchTags(
    { term: debouncedInputValue, contentType },
    {
      enabled: validateMinLength(debouncedInputValue, 1),
    },
  );

  return (
    <Stack spacing={1}>
      {headerHelperText}
      <FormAutocomplete
        autoComplete
        PaperComponent={CustomPaper}
        multiple={multiple}
        loading={isLoading}
        name={name || TAG_INPUT_NAME}
        label={label || TAG_INPUT_LABEL}
        placeholder={TAG_PLACEHOLDER}
        groupBy={(option) => option.typeLabel}
        getOptionLabel={(option) => option?.completeName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(e, value, reason) => {
          /**
           * This callback fired on input blur or when the
           * dropdown appeared, even with clearOnBlur set to false.
           * Had to ignore these reset events to prevent clearing
           * the input prematurely.
           */
          if (reason === 'reset') {
            return;
          }
          setInputValue(value);
        }}
        onChange={(value) => {
          setInputValue('');
          onChange(value);
        }}
        options={uniqBy(concat(value || [], tags || []), 'id')}
        noOptionsText="No matching tags"
        formFieldProps={formFieldProps}
        value={value}
        customReadOnly={readOnly}
        inputValue={inputValue}
        disabledIds={disabledIds}
        {...tagSelectorProps}
      />
      {footerHelperText}
    </Stack>
  );
};

export default TagSelector;
