import React, { useState } from 'react';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { SearchInput } from 'common/components/input';
import { Box, Button, Stack, Typography } from 'common/components/material';
import { BackButton } from 'common/components/navigation';
import { useDebounce, useDrawer, useView } from 'common/hooks';
import theme from 'common/theme';
import FilterChipList from 'features/explore/components/FilterChipList';
import FilterTextButton from 'features/explore/components/FilterTextButton';
import { useFilterState } from 'features/explore/hooks';
import { FilterDrawerProps } from 'features/explore/types';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { PrinciplesFilterTypeEnum } from '../principles.enums';
import { PrinciplesFilterExplorer } from './principles-filter-explorer.component';
import { ProfileSortByOption, useFetchProfilesByName } from 'features/search';

export const INIT_PROPS: FilterDrawerProps = {
  applyButtonHandler: () => {},
  initialFilters: {},
};

export const PrinciplesFilterDrawer: React.FC<FilterDrawerProps> = ({
  applyButtonHandler,
  initialFilters,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCollapsed, setSearchCollapsed] = useState(true);
  const searchInputRef = React.useRef<HTMLInputElement>();
  const { isDesktopView } = useView();
  const debouncedTerm = useDebounce(searchTerm);
  const { data } = useFetchProfilesByName({
    term: debouncedTerm,
    sortBy: ProfileSortByOption.ASSESSMENT_STATUS,
  });

  const { closeDrawer } = useDrawer();
  const [showFilterList, setShowFilterList] = useState(false);

  const { activeFilters, activeFilterMap, filterList, toggleFilterSelection, clearFilters } =
    useFilterState(PrinciplesFilterTypeEnum.PROFILE, initialFilters);

  const closeFilterDrawer = () => {
    closeDrawer(DrawerIdEnum.PRINCIPLES_FILTER);
  };

  const handleApply = () => {
    applyButtonHandler(activeFilters);
    closeFilterDrawer();
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
  };

  const handleSearchBlur = () => {
    setSearchCollapsed(true);
  };

  const toggleSearchCollapse = (collapsed: boolean) => {
    setSearchCollapsed(collapsed);
  };

  const header = (
    <Stack direction="row" justifyContent="space-between" px={1}>
      {showFilterList ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <BackButton
            size="small"
            clickHandler={() => {
              setShowFilterList(false);
            }}
          />
          <Typography variant="h4" fontWeight={600}>
            Filters
          </Typography>
        </Stack>
      ) : (
        <FilterTextButton
          filterCount={filterList.length}
          onClick={() => {
            setShowFilterList(true);
          }}
        />
      )}
      <Button
        btntype="tertiary"
        sx={{ fontSize: theme.typography.body3.fontSize, fontWeight: 500 }}
        onClick={clearFilters}
      >
        Clear All
      </Button>
    </Stack>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={false}
      isPrimaryActionDisabled={!filterList?.length}
      secondaryAction={closeFilterDrawer}
      primaryAction={handleApply}
      primaryActionLabel="Apply"
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <Box height="100%">
        {showFilterList ? (
          <FilterChipList
            filters={filterList}
            onDelete={(filter) => toggleFilterSelection(filter, false)}
          />
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingX="1rem"
            >
              {searchCollapsed && <Typography variant="h3">Add People</Typography>}
              <SearchInput
                inputRef={searchInputRef}
                collapsed={!isDesktopView && searchCollapsed}
                collapsible={!isDesktopView}
                value={searchTerm}
                onChange={handleSearch}
                onClear={handleSearchClear}
                onBlur={handleSearchBlur}
                onToggleCollapse={toggleSearchCollapse}
              />
            </Stack>
            <PrinciplesFilterExplorer
              data={data}
              activeFilterMap={activeFilterMap}
              toggleFilterSelection={toggleFilterSelection}
            />
          </>
        )}
      </Box>
    </FormDrawer>
  );
};
