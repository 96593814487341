import React from 'react';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';
import { Divider, List } from 'common/components/material';
import {
  AboutConnectXMenuItem,
  LogoutMenuItem,
  ManageOrganizationMenuItem,
  ManageOrganizationUsersMenuItem,
  CreateOrganizationMenuItem,
  ManageOrganizationStaticDataMenuItem,
} from 'common/components/menu-items';
import OrganizationLinkMenuItem from 'common/components/menu-items/organization-link-menu-item';
import ManageNotificationsMenuItem from 'common/components/menu-items/ManageNotificationsMenuItem';
import EditProfileMenuItem from 'common/components/menu-items/EditProfileMenuItem';
import MenuItemsHeader from 'common/components/menu-items/menu-items-header.component';
import ThemeSwitchListItem from 'common/components/theme/theme-switch-list-item.component';
import { useDrawer, useSession } from 'common/hooks';

import { useOrganizationPrivacyPolicy } from 'features/organization-privacy-policy/hooks/useGetOrganizationPrivacyPolicy';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import MenuDrawerHeader from './menu-drawer-header.component';

const SettingsDrawer: React.FC = () => {
  const { currentOrganization } = useSession();
  const { profile } = useSession();
  const { closeDrawer } = useDrawer();

  const supportChannelUrl = currentOrganization()?.supportChannelUrl;
  const { data: privacyPolicy } = useOrganizationPrivacyPolicy();
  const isAdmin = profile?.isAdmin();
  const adminSettings = isAdmin && (
    <>
      <MenuItemsHeader label="Admin Settings">
        <ManageOrganizationUsersMenuItem />
        <ManageOrganizationMenuItem />
        <ManageOrganizationStaticDataMenuItem />
      </MenuItemsHeader>
      <Divider />
    </>
  );

  const isSuperAdmin = profile?.isSuperAdmin;
  const superAdminSettings = isSuperAdmin && (
    <>
      <MenuItemsHeader label="Super Admin Settings">
        <CreateOrganizationMenuItem />
      </MenuItemsHeader>
      <Divider />
    </>
  );

  const userSettings = (
    <>
      <MenuItemsHeader label="User Settings">
        <EditProfileMenuItem />
        <ManageNotificationsMenuItem />
      </MenuItemsHeader>
      <Divider />
    </>
  );

  return (
    <>
      <MenuDrawerHeader label="Settings" onClick={() => closeDrawer(DrawerIdEnum.SETTINGS)} />
      <VerticalScrollableContainer disableGutters>
        <div onClick={() => closeDrawer(DrawerIdEnum.SETTINGS)}>
          <List>
            <AboutConnectXMenuItem />
            {supportChannelUrl && (
              <OrganizationLinkMenuItem
                Icon={ContactSupportOutlinedIcon}
                label="Support Channel"
                url={supportChannelUrl}
              />
            )}
            {privacyPolicy?.url && (
              <OrganizationLinkMenuItem
                Icon={InfoOutlinedIcon}
                label="Privacy policy statement"
                url={privacyPolicy?.url}
              />
            )}
          </List>
          <Divider />
          <ThemeSwitchListItem />
          {userSettings}
          {adminSettings}
          {superAdminSettings}
          <List>
            <LogoutMenuItem />
          </List>
        </div>
      </VerticalScrollableContainer>
    </>
  );
};

export default SettingsDrawer;
