import { useState } from 'react';

import { ProjectFormValue, WinPeriod } from '../types';
import InlineProjectForm from './inline-project-form.component';
import AddStaticDataButton from 'features/org-root/components/StaticData/AddStaticDataButton';
import { Profile } from 'features/profiles/models/profile.model';
import { Tag } from 'features/tags/types';

type InlineProjectProps = {
  owner?: Profile;
  tags?: Tag[];
  period?: WinPeriod;
  skipProjectCreation?: boolean;
  onSubmit?: (title: string) => void;
  autoFocusAddNewWinField?: boolean;
};

export const InlineProject: React.FC<InlineProjectProps> = ({
  tags,
  owner,
  period,
  skipProjectCreation = false,
  onSubmit,
  autoFocusAddNewWinField = false,
}) => {
  const [editMode, setEditMode] = useState(autoFocusAddNewWinField);

  const inlineProjectRequest = {
    title: '',
    description: '',
    skills: [],
    owner: owner?.id ? [owner] : owner,
    tags,
    period,
  } as ProjectFormValue;

  const openWinInput = () => {
    setEditMode(true);
  };

  return editMode ? (
    <InlineProjectForm
      data={inlineProjectRequest}
      skipProjectCreation={skipProjectCreation}
      onClose={() => setEditMode(false)}
      onSubmit={onSubmit}
      onOpen={openWinInput}
    />
  ) : (
    <AddStaticDataButton data-testid="inline-add-new-win" onClick={() => setEditMode(true)}>
      {' '}
      Add New
    </AddStaticDataButton>
  );
};
