import { get, isNil } from 'lodash';

import { InstancePermissionsResourcesEnum } from 'common/enum/InstancePermissions.enum';
import { Review, ReviewStatusEnum } from 'features/reviews';
import { useSession } from 'common/hooks';
import { OpportunityStatusEnum } from 'features/opportunities';
import { Praise } from 'features/praise';
import { Profile } from 'features/profiles/models/profile.model';
import { QuickLinkTypeEnum } from 'features/quick-links/quick-link-type.enum';

export type InstancePermissions = {
  read: boolean;
  create: boolean;
  edit: boolean;
};

const DEFAULT_PERMISSIONS = {
  read: true,
  create: false,
  edit: false,
};

const getInstancePermissions = <T>(
  resourceType: InstancePermissionsResourcesEnum,
  resource: T,
  loggedInUser: Profile,
): InstancePermissions => {
  const permissions = { ...DEFAULT_PERMISSIONS };
  const isAdmin = loggedInUser?.isAdmin();
  const isContentAdmin = loggedInUser?.isContentAdmin();
  const loggedInUserId = get(loggedInUser, 'id');

  switch (resourceType) {
    case InstancePermissionsResourcesEnum.PROFILE:
      permissions.edit = isAdmin || loggedInUserId === get(resource, 'id');
      break;
    case InstancePermissionsResourcesEnum.PROJECT:
      const ownerId = get(resource, 'ownerId') || get(resource, 'owner.id');
      const assignerId = get(resource, 'assignerId') || get(resource, 'assigner.id');
      permissions.edit =
        loggedInUserId === ownerId || loggedInUserId === assignerId || isAdmin;
      break;
    case InstancePermissionsResourcesEnum.PRAISE:
      const praise = resource as unknown as Praise;
      const receiverIsActive = get(praise, 'receiver.isActiveInOrganization', true);
      permissions.create = loggedInUserId !== get(praise, 'receiver.id');
      permissions.edit = loggedInUserId === get(praise, 'giver.id') && receiverIsActive;
      break;
    case InstancePermissionsResourcesEnum.REVIEW:
      const review = resource as unknown as Review;
      const revieweeId = get(review, 'reviewee.id');
      const reviewerId = get(review, 'reviewer.id');
      const revieweeIsActive = get(review, 'reviewee.isActiveInOrganization', true);
      const reviewStatus = get(review, 'status');
      permissions.read =
        reviewStatus === ReviewStatusEnum.COMPLETED &&
        [revieweeId, reviewerId].includes(loggedInUserId);
      permissions.edit =
        reviewStatus === ReviewStatusEnum.NOT_STARTED &&
        reviewerId === loggedInUserId &&
        revieweeIsActive;
      break;
    case InstancePermissionsResourcesEnum.OPPORTUNITY:
      permissions.edit =
        (loggedInUserId === get(resource, 'assigner.id') ||
        loggedInUserId === get(resource, 'manager.id')) &&
          get(resource, 'status') === OpportunityStatusEnum.OPEN;
      break;
    case InstancePermissionsResourcesEnum.POST:
      permissions.edit = loggedInUserId === get(resource, 'creator.id') || isAdmin;
      break;
    case InstancePermissionsResourcesEnum.QUICK_LINK:
      const quickLinkType = get(resource, 'quickLinkType');
      const profileId = get(resource, 'profileId');
      const linkedEntityId = get(resource, 'linkedEntityId') as string;

      const isLoggedInUserAllowed =
        quickLinkType === QuickLinkTypeEnum.STATIC_DATA
          ? loggedInUser.isPageAdminOf(linkedEntityId)
          : loggedInUserId === profileId;

      permissions.create = isLoggedInUserAllowed || isAdmin || isContentAdmin;
      permissions.edit = isLoggedInUserAllowed || isAdmin || isContentAdmin;
      break;
    case InstancePermissionsResourcesEnum.COMPANY_UPDATE:
    case InstancePermissionsResourcesEnum.ORGANIZATION_EVENT:
      const creatorId = get(resource, 'creatorId');
      permissions.create = isAdmin || isContentAdmin || loggedInUserId === creatorId;
      permissions.edit = isAdmin || isContentAdmin || loggedInUserId === creatorId;
      break;
    case InstancePermissionsResourcesEnum.STATIC_DATA:
      const staticDataId = get(resource, 'id') as string;
      const isAllowed = isAdmin || isContentAdmin || loggedInUser.isPageAdminOf(staticDataId);
      permissions.create = isAllowed;
      permissions.edit = isAllowed;
      break;
    case InstancePermissionsResourcesEnum.GROUP:
      const isPageAdmin = loggedInUser.isPageAdmin();
      permissions.create = isAdmin || isContentAdmin || isPageAdmin;
      permissions.edit = isAdmin || isContentAdmin || isPageAdmin;
  }
  return permissions;
};

const useInstancePermissions = <T>(
  resourceType: InstancePermissionsResourcesEnum,
  resource: T,
) => {
  const { profile: loggedInUser } = useSession();
  if (!isNil(loggedInUser)) {
    const permissions = getInstancePermissions<T>(resourceType, resource, loggedInUser);
    return { permissions };
  }
  return { permissions: DEFAULT_PERMISSIONS };
};

export default useInstancePermissions;
