export enum ProfileRoutesEnum {
  PROFILE = ':profileId/*',
  SKILLS = ':profileId/skills',
  ASPIRATIONS = ':profileId/aspirations',
  PROFILE_EDIT = ':profileId/edit',
  ACTIVITY = ':profileId/activity',
  EXPERIENCE = ':profileId/experience',
  WIN_LIST = ':profileId/win-list',
  PRAISE = ':profileId/praise',
}
