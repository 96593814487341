import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useRouter } from 'common/hooks';
import { Params } from 'common/hooks/useRouter';

const StyledMUILink = styled(MUILink)({
  cursor: 'pointer',
}) as typeof MUILink;

export type LinkProps = MUILinkProps & {
  params?: Params;
  external?: boolean;
  replace?: boolean;
  children?: React.ReactNode;
  state?: Record<string, any>;
  selectable?: boolean; // set to `true` to enable text selection
  clickHandler?: (e: React.MouseEvent) => void;
};

const Link: React.FC<LinkProps> = React.forwardRef(
  (
    { children, clickHandler, href = '', params = {}, external, selectable, ...props },
    ref,
  ) => {
    const [mousePosition, setMousePosition] = useState<{
      x: number | null;
      y: number | null;
    }>({ x: null, y: null });
    const { getOrgRoute } = useRouter();
    const externalHref = external ? href : undefined;
    const internalHref = external ? undefined : href && getOrgRoute(href, params);

    const handleMouseDown = (e: React.MouseEvent) => {
      // primary mouse button
      if (e.button === 0) {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = (e: React.MouseEvent) => {
      // Check if the mouse has moved significantly
      if (
        e.button === 0 && // primary mouse button
        mousePosition.x &&
        mousePosition.y &&
        Math.abs(mousePosition.x - e.clientX) < 5 &&
        Math.abs(mousePosition.y - e.clientY) < 5 &&
        typeof clickHandler === 'function'
      ) {
        clickHandler(e);
      }
    };

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      clickHandler && clickHandler(e);
    };

    const ignoreClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <StyledMUILink
        component={external ? 'a' : RouterLink}
        {...(selectable
          ? {
              onMouseDown: handleMouseDown,
              onMouseUp: handleMouseUp,
              onClick: ignoreClick,
            }
          : {
              onClick: handleClick,
            })}
        color="black"
        underline="none"
        variant="body3"
        // Path for default anchor element
        href={externalHref}
        // Path for React Router's Link for in-app navigation
        to={internalHref}
        ref={ref}
        {...props}
      >
        {children}
      </StyledMUILink>
    );
  },
);

export default Link;
