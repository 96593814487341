import { isEmpty } from 'lodash';
import { OrganizationTypeEnum } from 'common/enum/Organization.enum';
import { Education } from 'common/types/Education.type';
import { Organization } from 'common/types/Organization.type';
import { CompanyProfile, CompanyProfileOnSession } from '../types/company-profile.type';
import { AspiredSkill, SkillResponse } from 'features/skills';

export abstract class BaseProfile {
  id: string;
  isSuperAdmin: boolean;
  loginEmail: string;
  firstName: string;
  lastName: string;
  pronouns?: string | undefined;
  photo?: string | undefined;
  organizationProfile: {
    type: OrganizationTypeEnum;
    data: CompanyProfileOnSession;
  };
  profileEducations: Education[];
  organizations: Organization[];
  endorsedSkills: SkillResponse[];
  aspiredSkills: AspiredSkill[];
  organizationProfiles: CompanyProfile[];
  get isOnboarded() {
    return this.hasBasicInfo;
  }

  get hasBasicInfo() {
    return !isEmpty(this.firstName) || !isEmpty(this.lastName);
  }
}
