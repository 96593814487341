import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { Skill } from 'features/skills';
import { createOpportunityApi } from '../opportunities.service';

export const useCreateOpportunity = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter('OpportunityAdded');

  const mutationResponse = useMutation(createOpportunityApi, {
    showSuccessSnackbar: true,
    transformInput: (input) => {
      const { title, skills, description, tags, manager } = input;
      return {
        title,
        description,
        skillIds: skills.map((skill: Skill) => skill.id),
        tags,
        managerId: manager[0].id,
      };
    },
    onSuccess: () => {
      emitWithSettledAfter(5000);
    },
    ...options,
  });
  return mutationResponse;
};
