import { useQuery } from 'common/hooks';
import { getCompanyRolesApi } from '../companies.service';

export const useGetCompanyRoles = () => {
  const queryResponse = useQuery(['companyRoles'], () => getCompanyRolesApi(), {
    showErrorSnackbar: false,
    staleTime: Infinity,
  });

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse.data?.data?.roles : [],
  };
};
