import { map, pick } from 'lodash';

import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updatePraiseApi } from '../praise.service';
import { PraiseEventData } from '../types';

export const useUpdatePraise = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter<PraiseEventData>('PraiseUpdated');

  const mutationResponse = useMutation(updatePraiseApi, {
    showSuccessSnackbar: true,
    transformInput: (praiseForm: any) => {
      const praise = pick(praiseForm, [
        'id',
        'receiver',
        'headline',
        'description',
        'skills',
        'relatedProject',
        'tags',
      ]);
      return {
        id: praise.id,
        receiverId: praise.receiver?.[0]?.id,
        headline: praise.headline,
        description: praise.description,
        skillIds: map(praise.skills, (skill) => skill?.id),
        relatedProjectId: praise.relatedProject?.id,
        tags: praise.tags,
      };
    },
    onSuccess: async ({ data }) => {
      emitWithSettledAfter(5000, { data });
    },
    ...options,
  });
  return mutationResponse;
};

export default useUpdatePraise;
