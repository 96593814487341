import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { get } from 'lodash';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import BusinessIcon from '@mui/icons-material/Business';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { ReactComponent as CampusVisitIcon } from 'common/svg-icons/campus-visit.svg';
import { ReactComponent as CareerFairIcon } from 'common/svg-icons/career-fair.svg';
import { ReactComponent as StrategyAlignmentIcon } from 'common/svg-icons/strategy-alignment.svg';
import { ReactComponent as ClientVisitIcon } from 'common/svg-icons/client-visit.svg';
import { ReactComponent as EmployeeOnboardingIcon } from 'common/svg-icons/employee-onboarding.svg';
import { ReactComponent as ExpoIcon } from 'common/svg-icons/expo.svg';
import { ReactComponent as WebinarIcon } from 'common/svg-icons/webinar.svg';
import { ReactComponent as IonSpecificEventIcon } from 'common/svg-icons/ion-specific-event-icon.svg';
import { ReactComponent as OfficeClosureIcon } from 'common/svg-icons/office-closure.svg';
import { ReactComponent as SuperdayIcon } from 'common/svg-icons/superday.svg';
import { ReactComponent as TeamOffsiteIcon } from 'common/svg-icons/team-offsite.svg';
import { ReactComponent as UpskillingIcon } from 'common/svg-icons/upskilling.svg';
import { ReactComponent as OnsiteProjectIcon } from 'common/svg-icons/onsite-project.svg';

import {
  convertBetweenTimezones,
  convertDateTimeToUTC,
  zoneAbbr,
} from 'common/utils/date-time.utils';
import { OrganizationEvent } from '../types';
import { OrganizationEventLocationTypeEnum } from '../organization-events.enum';
import { WorkLocation } from 'features/static-data';
import { IconProps } from 'common/components/cx-icon/cx-icon.component';

export const getWorkLocationName = (officeLocation: WorkLocation) => {
  return officeLocation.city
    ? `${officeLocation.city.name} - ${officeLocation.name}`
    : officeLocation.name;
};

export const getEventLocationName = (organizationEvent: OrganizationEvent) => {
  const { eventLocationType, venue, officeLocation } = organizationEvent;

  switch (eventLocationType) {
    case OrganizationEventLocationTypeEnum.OFFICE:
      return officeLocation ? getWorkLocationName(officeLocation) : 'TBC';
    case OrganizationEventLocationTypeEnum.VENUE:
      return venue || 'TBC';
    case OrganizationEventLocationTypeEnum.ONLINE:
      return 'Online';
  }
};

export const getEventTimezoneOffset = (
  startDate: string,
  startTime: string,
  originTimezone: string,
  eventLocationType: OrganizationEventLocationTypeEnum,
) => {
  const userTimezone = moment.tz.guess();
  const eventTimezone =
    eventLocationType === OrganizationEventLocationTypeEnum.ONLINE
      ? userTimezone
      : originTimezone;
  const deltaOffset =
    moment.tz(userTimezone).utcOffset() - moment.tz(eventTimezone).utcOffset();

  if (deltaOffset === 0) {
    return '';
  }

  const timeInUsersTimezone = convertBetweenTimezones(
    eventTimezone,
    userTimezone,
    startDate,
    startTime,
  );

  return `${timeInUsersTimezone.format('h:mm A')} in your timezone (${zoneAbbr(
    userTimezone,
  )})`;
};

export const eventDatetime = (
  type: OrganizationEventLocationTypeEnum,
  utcDate: string,
  utcTime: string | undefined,
  originTimezone: string,
) => {
  if (!utcDate || !utcTime) {
    return {
      date: utcDate,
      time: utcTime,
    };
  }
  const dateString = `${utcDate} ${utcTime}`;
  const mnt =
    type === OrganizationEventLocationTypeEnum.ONLINE
      ? moment.utc(dateString).local()
      : momentTz.utc(dateString).tz(originTimezone);
  return {
    date: mnt.format('YYYY-MM-DD'),
    time: mnt.format('h:mm A'),
  };
};

export const transformOrganizationEventRequest = (formData: any) => {
  const {
    eventType,
    organizer,
    location,
    startDate: localStartDate,
    startTime: localStartTime,
    endDate: localEndDate,
    endTime: localEndTime,
    timeZone,
    eventUrl,
    ...otherFormValues
  } = formData;

  const organizerId = get(organizer, '[0].id');

  const { date: startDate, time: startTime } = convertDateTimeToUTC(
    timeZone?.name,
    localStartDate,
    localStartTime,
  );

  const { date: endDate, time: endTime } = convertDateTimeToUTC(
    timeZone?.name,
    localEndDate || localStartDate,
    localEndTime,
  );

  const transformedInput = {
    eventTypeId: eventType?.id,
    organizerId,
    officeLocationId: location?.id,
    originTimezone: timeZone?.name,
    startDate,
    startTime,
    endDate,
    endTime,
    ...otherFormValues,
  };

  if (eventUrl) {
    transformedInput.eventUrl = eventUrl;
  }

  return transformedInput;
};

export const QUARTER_MONTH_MAPPING = [
  [
    { label: 'Jan', month: 1 },
    { label: 'Feb', month: 2 },
    { label: 'Mar', month: 3 },
  ],
  [
    { label: 'Apr', month: 4 },
    { label: 'May', month: 5 },
    { label: 'Jun', month: 6 },
  ],
  [
    { label: 'Jul', month: 7 },
    { label: 'Aug', month: 8 },
    { label: 'Sep', month: 9 },
  ],
  [
    { label: 'Oct', month: 10 },
    { label: 'Nov', month: 11 },
    { label: 'Dec', month: 12 },
  ],
];

export const getTotalEventsLabel = (totalEvents?: number) => {
  if (!totalEvents) return 'No Events';

  const suffixString = totalEvents === 1 ? 'event' : 'events';

  return `${totalEvents} ${suffixString}`;
};

//'svg' are downloaded svg's and 'icon' are from mui/icons lib
export const EVENT_TYPE_ICONS_MAPPING: { [eventType: string]: IconProps } = {
  Collaboration: { svg: HandshakeIcon },
  'Company Updates': { muiIcon: PostAddIcon },
  Culture: { muiIcon: Diversity1Icon },
  Customer: { muiIcon: GroupIcon },
  'Public Holiday': { muiIcon: CalendarTodayIcon },
  Learning: { muiIcon: LocalLibraryIcon },
  Recruitment: { muiIcon: PersonSearchIcon },
  'Strategy Alignment': { svg: StrategyAlignmentIcon },
  'Team Offsite': { svg: TeamOffsiteIcon },
  'Product Demo': { muiIcon: LaptopMacIcon },
  Townhall: { muiIcon: RecordVoiceOverIcon },
  Athletic: { muiIcon: DirectionsRunIcon },
  ContributION: { svg: IonSpecificEventIcon },
  'Employee Lead': { muiIcon: HowToRegIcon },
  InclusION: { svg: IonSpecificEventIcon },
  'Office Party': { muiIcon: Diversity1Icon },
  Social: { muiIcon: CelebrationIcon },
  Wellness: { muiIcon: SelfImprovementIcon },
  'Client Visit': { svg: ClientVisitIcon },
  Expo: { svg: ExpoIcon },
  'Onsite Project': { svg: OnsiteProjectIcon },
  Roadshow: { muiIcon: EmojiTransportationIcon },
  Tradeshow: { muiIcon: StackedBarChartIcon },
  'Employee Onboarding': { svg: EmployeeOnboardingIcon },
  'Manager Training': { muiIcon: SensorOccupiedIcon },
  Upskilling: { svg: UpskillingIcon },
  Webinar: { svg: WebinarIcon },
  'No Office Closure': { muiIcon: BusinessIcon },
  'Office Closure': { svg: OfficeClosureIcon },
  'Campus Visit': { svg: CampusVisitIcon },
  'Career Fair': { svg: CareerFairIcon },
  Networking: { muiIcon: HandshakeIcon },
  Superday: { svg: SuperdayIcon },
};

export const UPCOMING_EVENTS_WIDGET_TEXT = `Based on pages you follow`;
