import React from 'react';
import { Snackbar } from 'common/components/material';
import ApplicationContextProvider from 'common/context/ApplicationContextProvider';
import { NotificationsContextProvider } from 'common/context/NotificationsContextProvider';
import AppRouter from 'common/routes/AppRouter';
import queryClient from 'common/utils/QueryClient';
import DrawerManager from 'features/org-root/components/DrawerManager';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'common/context/hooks/ThemeProvider';

const App: React.FC = () => {

  return (
    <QueryClientProvider client={queryClient}>
      <ApplicationContextProvider>
        <NotificationsContextProvider>
          <ThemeProvider>
            <Snackbar />
            <Router>
              {
                // TODO/ML: It is odd, that here, within a component from the "common" module, we end up importing and using DrawerManager which is instead defined inside the "features/org-root" module.
                //
                // I could have made DrawerManager part of the "common" module; however, we import **all** the different drawers in there (e.g. MenuDrawer, SkillActionDrawer, ReviewDrawer), and that means that moving DrawerManager inside "common" would still result in having a "common" component, i.e. DrawerManager, linking a bunch of components defined inside other and more specific modules.  And this, my friend, is exactly the same problem we set out to solve in the fist place!
                //
                // Honestly speaking, I am not exactly sure what a proper fix to this could look like; I guess we could offer a way to enable different component sub-trees to define their own set of drawers, keeping hence DrawerManager as generic as possible; however, that seemed like a lot of work especially considering the underlaying bug that we were aiming to squash (i.e. that the menu drawer icon was not doing anything, when pressed from the "Create organization" screen), so I decided to leave that as a future activity instead.
                //
                // Note: I originally wanted to install DrawerManager alongside Snackbar, but custom drawers might (and some already do) need access to the current route, but calling `useLocation()` without a parent Route actually results in an error, hence I wrapped DrawerManager inside the Root component.
              }
              <DrawerManager />
              <AppRouter />
            </Router>
          </ThemeProvider>
        </NotificationsContextProvider>
      </ApplicationContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
