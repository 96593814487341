import React from 'react';

import { Stack } from 'common/components/material';
import { FiltersMenuProps } from '../types/filter-menu-props.type';
import { RectangularSkeleton } from 'common/components/loaders';
import { useGetFilterMenuItems } from '../hooks/useGetFilterMenuItems';
import FilterMenuListItem from './filter-menu-item.component';

const FilterMenu: React.FC<FiltersMenuProps> = ({ activeFilters, selectedTab, onSelect }) => {
  const { menuItems = [], isLoading } = useGetFilterMenuItems(selectedTab);

  if (isLoading) return <RectangularSkeleton height="15rem" />;

  return (
    <Stack p={1} spacing={1}>
      {menuItems.map((menuItem) => {
        return (
          <FilterMenuListItem
            key={menuItem.key}
            menuItem={menuItem}
            activeFilters={activeFilters}
            onSelect={onSelect}
          />
        );
      })}
    </Stack>
  );
};

export default FilterMenu;
