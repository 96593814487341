import * as yup from 'yup';
import { SkillSchema } from 'features/skills';
import { TagSchema } from 'features/tags/schemas/tag.schema';
import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';

export const OpportunityFormSchema = yup
  .object({
    title: yup.string().label('title').required('Title is required'),
    skills: yup
      .array()
      .of(SkillSchema)
      .label('skills')
      .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
      .min(1, 'At least 1 skill is required')
      .required('At least 1 skill is required'),
    tags: yup.array().of(TagSchema).label('tags'),
    manager: yup
      .array()
      .label('manager')
      .min(1, 'Manager is required')
      .required('Manager is required'),
  })
  .required();
