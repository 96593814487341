import { FormProvider } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import {
  FormTextField,
  PeopleSelector,
  RecommendedSkills,
  SkillSelector,
  StyledForm,
} from 'common/components/input';
import { Box, Stack, Typography } from 'common/components/material';
import { SKILLS_MAX_LIMIT, TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';
import { useDeferredState } from 'common/hooks';
import { useGetProfile } from 'features/profiles';
import { Skill } from 'features/skills';
import TagSelector from 'features/tags/components/tag-selector.component';
import { Tag } from 'features/tags/types';
import { CXEditorField } from 'libs/toast-ui';
import { TitleInput } from '../styles/project-form.styles';
import { ProjectFormProps } from '../types';
import WinPeriodSelector from './win-period-selector.component';
import { useFetchProfilesByName, useFetchReportees } from 'features/search/hooks';

const ProjectForm: React.FC<ProjectFormProps> = ({ project, form, forceTitleAutoFocus }) => {
  const { palette } = useTheme();
  const [requiredSkills, setRequiredSkills] = useDeferredState<Array<Skill>>(project?.skills);
  const [tags, setTags] = useDeferredState<Tag[]>(project?.tags);
  const { data: loggedInUser } = useGetProfile();
  const isAdmin = loggedInUser?.isAdmin();

  const ownerOptionsApiHook = isAdmin ? useFetchProfilesByName : useFetchReportees;
  const ownerApiParams = isAdmin ? {} : { includeSelf: true };

  const { title, description } = project;

  const titleAutoFocus = forceTitleAutoFocus || title.length === 0;
  const descriptionAutoFocus = !titleAutoFocus && description?.length === 0;

  return (
    <FormProvider {...form}>
      <StyledForm noValidate>
        <Stack spacing={3} m={2} pb={2}>
          <TitleInput
            name="title"
            label="Initiative Name"
            autoFocus={titleAutoFocus}
            helperText="Highlight your most important initiatives for this quarter"
            inputProps={{
              maxLength: TITLE_CHARACTER_LIMIT,
            }}
          />
          <CXEditorField
            name="description"
            placeholder="Add description"
            autoFocus={descriptionAutoFocus}
          />
          <PeopleSelector
            name="owner"
            label="Owner"
            selectedProfiles={project?.owner}
            limit={1}
            optionsApiHook={ownerOptionsApiHook}
            apiParams={ownerApiParams}
          />
          <FormTextField name="url" label="URL (Optional)" />
          <WinPeriodSelector
            name="period"
            label="Win Period"
            onChange={(period) => {
              form.setValue('period', period, { shouldValidate: true });
            }}
          />
          <Box>
            <SkillSelector
              name="skills"
              label="Add Required Skills"
              value={requiredSkills}
              placeholder="Type to search"
              onChange={(skills) => {
                setRequiredSkills(skills);
              }}
            />
            <Stack direction="row" mt={1.5} justifyContent={'space-between'}>
              <RecommendedSkills
                name="skills"
                selectedSkills={requiredSkills}
                onChange={(skills) => {
                  setRequiredSkills(skills);
                }}
              />
              <Typography
                variant="caption"
                color={
                  (requiredSkills?.length || 0) > 0
                    ? palette.Success
                    : palette.TextFields.HintText
                }
              >
                {`${requiredSkills?.length || 0} / ${SKILLS_MAX_LIMIT} skills`}
              </Typography>
            </Stack>
          </Box>

          {isAdmin && (
            <TagSelector
              value={tags}
              onChange={(tags) => {
                setTags(tags);
              }}
            />
          )}
        </Stack>
      </StyledForm>
    </FormProvider>
  );
};

export default ProjectForm;
