export enum OrganizationTypeEnum {
  COMPANY = 'COMPANY',
  COMMUNITY = 'COMMUNITY',
  INSTITUTION = 'INSTITUTION',
}

export enum OrganizationRolesEnum {
  ADMIN = 'ADMIN',
  USER = 'USER',
  CONTENT_ADMIN = 'CONTENT_ADMIN',
}

export enum OrganizationRolesLabelEnum {
  ADMIN = 'Administrator',
  USER = 'User',
  CONTENT_ADMIN = 'Content Admin',
}

export enum OrganizationRolesDescriptionEnum {
  ADMIN = `Admins can view, create and delete everything, 
    including other users and their performance reviews.`,
  USER = `Users can view other profiles in their organization,
    and create praise, projects and opportunities.`,
  CONTENT_ADMIN = `Content Admins can create Updates and Events for all pages and Edit page details`,
}
