import axios from 'common/utils/axios';
import { QuickLink } from './types';

const api = axios('quick-links');

export const getQuickLinksApi = (linkedEntityId: string) => {
  return api.get(linkedEntityId);
};

export const createQuickLinkApi = ({ ...data }: any) => {
  return api.post('', data);
};

export const updateQuickLinkApi = ({ id, ...data }: QuickLink) => {
  return api.put(id, data);
};

export const deleteQuickLinkApi = (id: string) => {
  return api.delete(id);
};

export const searchQuickLinksApi = (params: any) => {
  return api.get('search', { params });
};
