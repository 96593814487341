import { useGetVisibleOrganizationalStaticData } from 'features/static-data';
import { ACTIVE_TAB_FILTERS_CONFIG } from '../explore.constants';
import { getVisibleFilterMenuItems } from '../explore.utils';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';

export const useGetFilterMenuItems = (selectedTab: ExploreTabEnum) => {
  const { data: visibleStaticDataTypes = [], isLoading } =
    useGetVisibleOrganizationalStaticData();
  const currentTabMenuConfig =
    selectedTab in ACTIVE_TAB_FILTERS_CONFIG ? ACTIVE_TAB_FILTERS_CONFIG[selectedTab] : [];

  if (isLoading) {
    return {
      menuItems: [],
      isLoading,
    };
  }

  return {
    menuItems: getVisibleFilterMenuItems(
      currentTabMenuConfig as ExplorerDataTypeEnum[],
      visibleStaticDataTypes,
    ),
    isLoading,
  };
};
