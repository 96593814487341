import { useQuery, useSession } from 'common/hooks';
import { getVisibleOrganizationalStaticDataApi } from '../services/static-data.service';

export const useGetVisibleOrganizationalStaticData = () => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';

  const queryResponse = useQuery(
    [organizationId, 'enabled-org-static-data-types'],
    getVisibleOrganizationalStaticDataApi,
    {
      refetchOnMount: false,
    },
  );
  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};
