import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { regURL } from 'common/validation-schemas/Common.schema';
import { capitalize, get, isEmpty } from 'lodash';

export const NO_ORG = 'no-org';
export const ORGANIZATION_ID_HEADER_KEY = 'X-Organization-Id';
export const ORGANIZATION_ID_KEY = 'organization-id';
export const ORGANIZATION_SUBURL_KEY = 'organization-suburl';

// Checks if string is a valid email
export const validateEmail = (text = '') => {
  return !!text.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/);
};

// Typical character length requirement to perform search
const DEFAULT_MIN_LENGTH = 3;

export const validateMinLength = (value?: string, minLength = DEFAULT_MIN_LENGTH) => {
  return !!value && value.length >= minLength;
};

export const validateUrlProtocol = (url?: string) => {
  if (!url) {
    return;
  } else if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }

  return url;
};

export const validateUrl = (url?: string) => {
  if (!url) {
    return false;
  }

  return !!url.match(regURL);
};

export const getString = (value: any, path: string, defaultValue?: string) => {
  if (typeof value === 'string') {
    return value;
  }
  return get(value, path, defaultValue);
};

export const extractOrgSubUrl = (route: string) => {
  const orgSubUrl = route.split('/')[1];

  const otherRoutes = Object.values(AppRoutesEnum).map((definedRoute) => {
    return definedRoute.split('/')[1];
  });

  otherRoutes.push(NO_ORG);
  const isOrgPath = !otherRoutes.includes(orgSubUrl);
  const subUrl = isOrgPath && !isEmpty(orgSubUrl) ? orgSubUrl : undefined;
  return { isOrgPath, subUrl };
};

export const getNoSelectorOptionsText = ({ hasData, isLoading, label, preload }: any) => {
  if (hasData) {
    return `No matching ${label}`;
  }
  if (preload) {
    return isLoading ? 'Loading data...' : 'No options available';
  }
  return `Enter at least 3 characters to search ${label}`;
};

export const titleCase = (value: string = '') => {
  return value.split(/[_ ]/).map(capitalize).join(' ');
};
