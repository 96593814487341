import React from 'react';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTechOutlined';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import PostAddIcon from '@mui/icons-material/PostAddOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenterOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAddOutlined';

import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';
import { Container, MenuList } from 'common/components/material';
import { useDrawer, useSession } from 'common/hooks';
// import ReviewMenuIconImage from 'common/components/image/ReviewMenuIcon';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import MenuButtonItem from './MenuButtonItem';
import { CreateEntityListProps } from 'features/global-create-button';
import { styled } from '@mui/material';
import { PostIcon } from 'common/components/image';

export const IconContainer = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  background: theme.palette.Icons.Secondary.BgDefault,
  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
    color: theme.palette.Icons.Secondary.IconFill,
  },
}));

const MenuContainer = styled(Container)({
  py: 1,
});

const CreateEntityList: React.FC<CreateEntityListProps> = ({ handleClick }) => {
  const { openDrawer } = useDrawer();
  const { profile } = useSession();
  const isAdmin = profile?.isAdmin();
  const isContentAdmin = profile?.isContentAdmin();
  const isPageAdmin = profile?.isPageAdmin();

  const openPostDrawer = () => {
    openDrawer(DrawerIdEnum.POST, {
      post: null,
      mandatorySelectBob: false,
    });
  };

  const openPraiseDrawer = () => {
    openDrawer(DrawerIdEnum.PRAISE, {
      praise: null,
    });
  };

  /* Last minute changes for Show & Tell */
  const openProjectDrawer = () => {
    openDrawer(DrawerIdEnum.PROJECT, {
      project: { owner: profile },
      selectedTags: [],
    });
  };

  const openOpportunityDrawer = () => {
    openDrawer(DrawerIdEnum.OPPORTUNITY, {
      opportunity: {},
      selectedTags: [],
    });
  };

  const openCompanyUpdatesDrawer = () => {
    openDrawer(DrawerIdEnum.COMPANY_UPDATE, {
      companyUpdate: {},
      selectedTags: [],
      isComingFromGlobalCreateButton: true,
    });
  };

  // const openReviewDrawer = () => {
  //   openDrawer(DrawerIdEnum.REVIEW, { reviewee: profile?.id });
  // };

  const openOrganizationEventDrawer = () => {
    openDrawer(DrawerIdEnum.ORGANIZATION_EVENT, {
      organizationEvent: null,
      selectedTags: [],
      isComingFromGlobalCreateButton: true,
    });
  };

  return (
    <MenuContainer onClick={handleClick} disableGutters={true}>
      <VerticalScrollableContainer disableGutters maxHeight="20rem">
        <MenuList>
          <MenuButtonItem
            label="Post"
            onClick={openPostDrawer}
            data-analytics-tag="create_button_option"
          >
            <IconContainer>
              <PostIcon />
            </IconContainer>
          </MenuButtonItem>
          <MenuButtonItem
            label="Praise"
            onClick={openPraiseDrawer}
            data-analytics-tag="create_button_option"
          >
            <IconContainer>
              <MilitaryTechIcon />
            </IconContainer>
          </MenuButtonItem>
          <MenuButtonItem
            label="What's Important Now"
            onClick={openProjectDrawer}
            data-analytics-tag="create_button_option"
          >
            <IconContainer>
              <PlaylistAddIcon />
            </IconContainer>
          </MenuButtonItem>
          <MenuButtonItem
            label="Opportunity"
            onClick={openOpportunityDrawer}
            data-analytics-tag="create_button_option"
          >
            <IconContainer>
              <BusinessCenterIcon />
            </IconContainer>
          </MenuButtonItem>

          {/* TODO some issue with the review drawer, not able to un-populate the reviewee from the drawer. Will do in a follow up MR. */}

          {/* {isAdmin && (
            <MenuButtonItem
              label="Review"
              onClick={() => openReviewDrawer()}
            >
              <ReviewMenuIconImage />
            </MenuButtonItem>
          )} */}
          {(isAdmin || isContentAdmin || isPageAdmin) && (
            <MenuButtonItem
              label="Company Updates"
              onClick={openCompanyUpdatesDrawer}
              data-analytics-tag="create_button_option"
            >
              <IconContainer>
                <PostAddIcon />
              </IconContainer>
            </MenuButtonItem>
          )}
          {(isAdmin || isContentAdmin || isPageAdmin) && (
            <MenuButtonItem
              label="Event"
              onClick={openOrganizationEventDrawer}
              data-analytics-tag="create_button_option"
            >
              <IconContainer>
                <EventNoteIcon />
              </IconContainer>
            </MenuButtonItem>
          )}
        </MenuList>
      </VerticalScrollableContainer>
    </MenuContainer>
  );
};

export default CreateEntityList;
