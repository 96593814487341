import * as yup from 'yup';

import { SkillSchema } from 'features/skills';
import { TagSchema } from 'features/tags/schemas/tag.schema';
import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';
import { regURL } from 'common/validation-schemas/Common.schema';

export const ProjectFormSchema = (isTagsRequired: boolean) => {
  return yup
    .object({
      owner: yup
        .array()
        .label('owner')
        .nullable()
        .test({
          name: 'owner-is-required-without-tags',
          message: 'Owner is required',
          test: (owner, context) => {
            owner = owner ?? [];
            const tags = context.parent.tags ?? [];
            if (isTagsRequired) return true; // i.e., owner is not required
            if (tags.length === 0) {
              return owner.length > 0;
            }
            return true;
          },
        })
        .test({
          name: 'max-open-wins-per-period',
          message:
            'Owner has reached the limit of maximum 3 open WINs for the selected period',
          test: (owner, context) => {
            owner = owner ?? [];
            if (owner.length > 0) {
              return context.parent.canCreateOpenWinForPeriod;
            }
            return true;
          },
        }),
      period: yup
        .object()
        .required('Win Period is required')
        .nullable()
        .test({
          name: 'max-open-wins-per-period',
          message:
            'Owner has reached the limit of maximum 3 open WINs for the selected period',
          test: (_, context) => {
            const owner = context.parent.owner ?? [];
            if (owner.length > 0) {
              return context.parent.canCreateOpenWinForPeriod;
            }
            return true;
          },
        }),
      title: yup.string().label('title').required('Title is required'),
      description: yup.string().label('description').nullable(),
      url: yup
        .string()
        .label('url')
        .test({
          name: 'valid-url',
          message: 'Invalid URL',
          test: (url) => {
            if (!url) {
              return true;
            }
            return !!url.match(regURL);
          },
        })
        .nullable(),
      skills: yup
        .array()
        .of(SkillSchema)
        .label('skills')
        .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
        .nullable(),
      tags: yup
        .array()
        .of(TagSchema)
        .label('tags')
        .nullable()
        .test({
          name: 'tags-is-required',
          message: 'Tags is required',
          test: (tags, context) => {
            const owner = context.parent.owner ?? [];
            tags = tags ?? [];
            if (isTagsRequired) {
              return tags.length > 0;
            } else {
              if (owner.length === 0) {
                return tags.length > 0;
              }
            }
            return true;
          },
        }),
    })
    .required();
};
