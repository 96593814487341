import { useDebounce, useQuery, useSession } from 'common/hooks';
import { validateMinLength } from 'common/utils/app.utils';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import { fetchSearchSuggestionsApi } from '../search.service';

export const useFetchSearchSuggestions = (searchTerm: string) => {
  const { account: user } = useSession();

  const debouncedTerm = useDebounce(searchTerm);

  const { data, isLoading, ...query } = useQuery(
    [user?.organizationId, 'search-suggestions', debouncedTerm],
    () => fetchSearchSuggestionsApi(debouncedTerm),
    {
      enabled: validateMinLength(debouncedTerm, 1),
    },
  );

  const { people, staticData } = data?.data || {};

  return {
    ...query,
    isLoading,
    people: (people ?? []) as IProfileSearchSuggestion[],
    staticData: (staticData ?? []) as IStaticDataSearchSuggestion[],
  };
};
