import { cloneDeep, isEmpty } from 'lodash';

import { useGetSearchResultsCount } from 'features/search/hooks/useGetSearchResultCounts';
import { EXPLORE_TABS } from '../explore.constants';
import {
  ExploreOrganizationTabType,
  ExploreOrganizationTabsConfig,
} from 'features/org-root/config/explore-organization-tabs.config';

export const useExploreTabs = () => {
  const response = useGetSearchResultsCount();
  let exploreTabs = cloneDeep(EXPLORE_TABS);

  if (!isEmpty(response.data)) {
    const {
      profiles,
      opportunities,
      praises,
      companyUpdates,
      organizationEvents,
      videos,
      quickLinks,
      winLists,
    } = response.data;

    exploreTabs = [
      { ...ExploreOrganizationTabsConfig.PEOPLE, badgeLabel: profiles },
      { ...ExploreOrganizationTabsConfig.OPPORTUNITIES, badgeLabel: opportunities },
      { ...ExploreOrganizationTabsConfig.PRAISE, badgeLabel: praises },
      { ...ExploreOrganizationTabsConfig.COMPANY_UPDATES, badgeLabel: companyUpdates },
      { ...ExploreOrganizationTabsConfig.EVENTS, badgeLabel: organizationEvents },
      { ...ExploreOrganizationTabsConfig.VIDEOS, badgeLabel: videos },
      // { ...ExploreOrganizationTabsConfig.QUICK_LINKS, badgeLabel: quickLinks },
      { ...ExploreOrganizationTabsConfig.WIN_LIST, badgeLabel: winLists },
    ] as ExploreOrganizationTabType[];
  }

  return {
    exploreTabs,
  };
};
