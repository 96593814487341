import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import ExploreView from 'features/explore/components/ExploreView';
import { ExploreRoutesEnum } from './explore.enums';
import NavigateToOrgRoute from 'common/components/navigation/NavigateToOrgRoute';

const ExploreRouter: React.FC = () => {
  const defaultPeopleRoute = () => {
    if (!window.location.search) {
      return ExploreRoutesEnum.PEOPLE;
    }
    return `${ExploreRoutesEnum.PEOPLE}${window.location.search}`;
  };
  return (
    <Routes>
      <Route path={ExploreRoutesEnum.PEOPLE} element={<ExploreView />} />
      <Route
        path={ExploreRoutesEnum.OPPORTUNITIES}
        element={<ExploreView selectedTab={ExploreTabEnum.OPPORTUNITIES} />}
      />
      <Route
        path={ExploreRoutesEnum.ALUMNI}
        element={<ExploreView selectedTab={ExploreTabEnum.ALUMNI} />}
      />
      <Route
        path={ExploreRoutesEnum.BEHAVIORS}
        element={<ExploreView selectedTab={ExploreTabEnum.BEHAVIOR} />}
      />
      <Route
        path={ExploreRoutesEnum.PROJECTS}
        element={<ExploreView selectedTab={ExploreTabEnum.PROJECTS} />}
      />
      <Route
        path={ExploreRoutesEnum.PRAISE}
        element={<ExploreView selectedTab={ExploreTabEnum.PRAISE} />}
      />
      <Route
        path={ExploreRoutesEnum.REVIEWS}
        element={<ExploreView selectedTab={ExploreTabEnum.REVIEWS} />}
      />
      <Route
        path={ExploreRoutesEnum.COMPANY_UPDATES}
        element={<ExploreView selectedTab={ExploreTabEnum.COMPANY_UPDATES} />}
      />
      <Route
        path={ExploreRoutesEnum.ORGANIZATION_EVENTS}
        element={<ExploreView selectedTab={ExploreTabEnum.ORGANIZATION_EVENTS} />}
      />
      <Route
        path={ExploreRoutesEnum.VIDEOS}
        element={<ExploreView selectedTab={ExploreTabEnum.VIDEOS} />}
      />
      {/* <Route
        path={ExploreRoutesEnum.QUICK_LINKS}
        element={<ExploreView selectedTab={ExploreTabEnum.QUICK_LINKS} />}
      /> */}
      <Route
        path={ExploreRoutesEnum.WIN_LIST}
        element={<ExploreView selectedTab={ExploreTabEnum.WIN_LIST} />}
      />
      <Route
        path="*"
        element={<NavigateToOrgRoute route={defaultPeopleRoute()} replace={true} />}
      />
    </Routes>
  );
};

export default ExploreRouter;
