import React, { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material/styles';

import Accordion, { AccordionDetails } from 'common/components/material/Accordion';
import { Skeleton, Stack } from 'common/components/material';

import { ProjectAccordionSummary } from '../styles/project-accordion-summary.styles';
import ProjectAccordionDetails from './project-accordion-details';
import { useGetProject } from '../hooks';
import { ManageProjectRequest, ReadViewProject } from '../types';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import {
  NonAccordionProjectHeader,
  ProjectItemHeader,
} from '../styles/project-item-header.styles';
import { isProjectContentAvailable } from '../project.utils';

export const ProjectItemView: React.FC<{
  project: ReadViewProject | ManageProjectRequest;
  headerActionElement?: React.ReactNode;
  defaultExpanded?: boolean;
}> = ({ project, headerActionElement, defaultExpanded }) => {
  const { palette } = useTheme();

  const [open, setOpen] = useState(false);
  const {
    data: detailedProject,
    isLoading,
    isSuccess,
  } = useGetProject(project.id, { enabled: open && !!project.id });

  const isContentAvailable = isProjectContentAvailable(project as ReadViewProject, false);

  const projectHeader = (
    <ProjectItemHeader>
      <EllipsisText
        lineclamp={open ? 4 : 1}
        ml={0.5}
        variant="body3"
        fontWeight={500}
        color={palette.Text.Headline}
      >
        {project.title}
      </EllipsisText>
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        {headerActionElement}
      </Stack>
    </ProjectItemHeader>
  );

  if (!project.description) {
    return (
      <NonAccordionProjectHeader data-testid="win-list-item">
        {projectHeader}
      </NonAccordionProjectHeader>
    );
  }

  return (
    <Accordion
      sx={{
        boxShadow: 'unset',
        width: 1,
        background: palette.Container.Bg,
      }}
      data-testid="win-list-item"
      // The content of Accordions is mounted by default even if the accordion is not expanded.
      // By enabling the unmountOnExit, we prevent the default behaviour.
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={defaultExpanded}
      onChange={(e, expanded) => setOpen(expanded)}
    >
      <ProjectAccordionSummary
        expandIcon={<NavigateNextIcon data-testid="toggle-win-details-section-button" />}
        sx={{ pr: 0, pl: 1 }}
        id={project.id}
      >
        {projectHeader}
      </ProjectAccordionSummary>
      <>
        {isSuccess && !isLoading ? (
          <AccordionDetails sx={{ ml: 2, pt: 0 }}>
            <ProjectAccordionDetails project={detailedProject} />
          </AccordionDetails>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ borderRadius: '0.75rem', mx: 2, mb: 3 }}
            height="6rem"
          />
        )}
      </>
    </Accordion>
  );
};
