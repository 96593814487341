import { get, pick, omit } from 'lodash';

import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createProjectApi } from '../services/projects.service';
import { Project } from '../types';

export const useCreateProject = (options: UseMutationOptions = {}) => {
  const { emit } = useEventEmitter<Project>('ProjectAdded');

  const mutationResponse = useMutation(createProjectApi, {
    showSuccessSnackbar: true,
    transformInput: (input: any) => {
      const projectFormValue = pick(input, [
        'title',
        'description',
        'url',
        'owner',
        'period',
        'skills',
        'tags',
      ]);
      return {
        ...omit(projectFormValue, 'owner', 'period'),
        periodId: get(projectFormValue, 'period.id'),
        ownerId: get(projectFormValue, 'owner[0].id'),
        skillIds: get(projectFormValue, 'skills').map((skill: any) => skill.id),
      };
    },
    onSuccess: ({ data }) => {
      emit(data);
    },
    ...options,
  });
  return mutationResponse;
};
