import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { TagSchema } from 'features/tags/schemas/tag.schema';

export const CompanyUpdateFormSchema = (isTagsRequired: boolean) => {
  return yup.object().shape({
    onBehalfOf: yup
      .object()
      .label('onBehalfOf')
      .nullable()
      .required('"On behalf of" is required'),
    title: yup
      .string()
      .label('title')
      .required('A headline is required for a company update.'),
    description: yup.string().label('description').required('Description is required'),
    tags: yup
      .array()
      .of(TagSchema)
      .label('tags')
      .when([], {
        is: () => isTagsRequired,
        then: (schema) =>
          schema.test({
            message: 'At least one tag is required',
            test: (tags) => !isEmpty(tags),
          }),
      }),
  });
};
