import React from 'react';
import moment from 'moment';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import NotesIcon from '@mui/icons-material/Notes';

import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { OrgChartIcon } from 'common/components/image';

export type ExploreOrganizationTabType = {
  value: string;
  label: string;
  pathName: string;
  defaultIcon?: React.FC<any>;
  activeIcon?: React.FC<any>;
  IconComponent?: React.FC<any>;
  adminOnly?: boolean;
  badgeLabel?: string;
};

export const ExploreOrganizationTabsConfig: { [tab: string]: ExploreOrganizationTabType } = {
  PEOPLE: {
    value: 'people/card',
    label: 'People',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: GroupsOutlinedIcon,
    activeIcon: GroupsIcon,
  },
  ORG_CHART: {
    value: '',
    label: 'Org Chart',
    pathName: AppRoutesEnum.ORG_CHART_ROOT,
    IconComponent: OrgChartIcon,
    adminOnly: false,
  },
  OPPORTUNITIES: {
    value: 'opportunities',
    label: 'Opportunities',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: BusinessCenterOutlinedIcon,
    activeIcon: BusinessCenterIcon,
  },
  PRAISE: {
    value: 'praise',
    label: 'Praise',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: MilitaryTechOutlinedIcon,
    activeIcon: MilitaryTechRoundedIcon,
  },
  COMPANY_UPDATES: {
    value: 'company-updates',
    label: 'Company Updates',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: PostAddRoundedIcon,
    activeIcon: PostAddRoundedIcon,
  },
  EVENTS: {
    value: `events/following/month/${moment().format('MMM')}/${moment().format('YYYY')}`,
    label: 'Events',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: EventNoteOutlinedIcon,
    activeIcon: EventNoteOutlinedIcon,
    adminOnly: false,
  },
  VIDEOS: {
    value: 'videos',
    label: 'Videos',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: VideocamOutlinedIcon,
    activeIcon: VideocamOutlinedIcon,
  },
  // QUICK_LINKS: {
  //   value: 'quick-links',
  //   label: 'Quick Links',
  //   pathName: AppRoutesEnum.EXPLORE,
  //   defaultIcon: LinkIcon,
  //   activeIcon: LinkIcon,
  // },
  WIN_LIST: {
    value: 'win-lists',
    label: 'WIN List',
    pathName: AppRoutesEnum.EXPLORE,
    defaultIcon: NotesIcon,
    activeIcon: NotesIcon,
  },
};
