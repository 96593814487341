import React, { useState, useEffect } from 'react';

import { NavigateToOrgProfile } from 'common/components/navigation';
import { useSession } from 'common/hooks';

type AdminRouteProp = {
  element: React.ReactElement;
};

const AdminRoute: React.FC<AdminRouteProp> = ({ element }) => {
  const { profile: loggedInProfile } = useSession();
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    setIsAdmin(loggedInProfile?.isAdmin() || false);
  }, [loggedInProfile]);

  if (!isAdmin) {
    return <NavigateToOrgProfile state={{ resetHistoryIndex: true }} />;
  }

  return element;
};

export default AdminRoute;
