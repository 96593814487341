import React from 'react';
import { useSearchParams } from 'react-router-dom';

import MenuItemButton from 'common/components/menu-items/MenuItemButton';
import { Box } from 'common/components/material';
import { SvgIconState } from 'common/components/image';
import { useRouter } from 'common/hooks';

import { EXPLORE_HOME_TABS } from 'features/explore/explore.constants';
import MenuItemsHeader from './menu-items-header.component';

const ExploreMenuItems: React.FC = () => {
  const { goTo } = useRouter();
  const [searchParams] = useSearchParams();

  const redirectToExploreTab = (value: string, pathname: string) => {
    if (value) {
      pathname += `/${value}`;
    }

    searchParams.delete('eventType');
    searchParams.delete('selectedDate');

    goTo({
      pathname,
      search: searchParams,
    });
  };

  return (
    <MenuItemsHeader label="Explore" dense>
      {EXPLORE_HOME_TABS.map(
        ({
          value,
          pathName,
          IconComponent,
          defaultIcon = React.Fragment,
          activeIcon = React.Fragment,
          label,
        }) => {
          return (
            <MenuItemButton
              key={label}
              onClick={() => redirectToExploreTab(value, pathName)}
              label={label}
              listItemSx={{ lineHeight: '1rem' }}
            >
              <Box className="list-item-icon">
                {IconComponent ? (
                  <IconComponent />
                ) : (
                  <SvgIconState DisabledIcon={defaultIcon} ActiveIcon={activeIcon} />
                )}
              </Box>
            </MenuItemButton>
          );
        },
      )}
    </MenuItemsHeader>
  );
};

export default ExploreMenuItems;
