import React from 'react';

import AspirationChip from 'features/aspirations/components/aspiration-chip.component';
import EndorsedSkillChip from 'features/org-root/components/EndorsedSkillChip';
import { SkillChipProps } from 'features/skills';
import { MUIRef } from 'common/types/MUIRef.type';

const SkillChip: React.FC<SkillChipProps> = React.forwardRef(
  ({ skill, isSelected, onClick, ...props }, ref: MUIRef) => {
    const { isAspiredSkill, name, isEndorsedByLoggedInUser, score } = skill;
    const SkillChipComponent = isAspiredSkill ? AspirationChip : EndorsedSkillChip;

    const handleSkillClick = () => {
      onClick && onClick(skill);
    };

    return (
      <SkillChipComponent
        ref={ref}
        clickable={!!onClick}
        isEndorsedByLoggedInUser={isEndorsedByLoggedInUser}
        score={score}
        isSelected={isSelected}
        label={name}
        onClick={handleSkillClick}
        {...props}
      />
    );
  },
);

export default SkillChip;
