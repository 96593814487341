import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material';

import { IconButton, Stack, Typography } from 'common/components/material';
import { useDrawer, useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

export const NotificationDrawerLabel: React.FC = () => {
  const { goTo } = useRouter();
  const { palette } = useTheme();
  const { closeDrawer } = useDrawer();
  const { isMobileView } = useView();
  const goToManageNotifications = () => {
    goTo(AppRoutesEnum.MANAGE_NOTIFICATIONS);
    closeDrawer(DrawerIdEnum.NOTIFICATION);
  };
  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
      onClick={goToManageNotifications}
    >
      <Stack>
        <Typography
          variant={isMobileView ? 'h4' : 'h2'}
          fontWeight={700}
          ml={2}
          color={palette.Text.Headline}
        >
          Notifications
        </Typography>
      </Stack>
      <Stack justifyItems={'right'} justifyContent={'right'}>
        <IconButton
          sx={{ color: palette.Icons.Tertiary.IconFillDefault }}
          onClick={goToManageNotifications}
        >
          <SettingsIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
