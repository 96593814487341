import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled, useTheme } from '@mui/material/styles';
import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';
import { ImageAvatar } from '../image-avatar/image-avatar';
import { Container, Typography } from 'common/components/material';
import { ListItemAvatar } from 'common/components/material/ListItemButton';
import MenuList from 'common/components/material/MenuList';
import colorPalette from 'common/theme/colorPalette';
import { Organization, OrganizationDropdownType } from 'common/types/Organization.type';
import React from 'react';
import CustomBadge from '../core/CustomBadge';
import { HoverableItem } from '../container/HoverableItem';

const OrganizationNameContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
});

type OrganizationListProps = {
  organizationList: OrganizationDropdownType[];
  organizationClickHandler: (organization: Organization) => void;
};

const OrganizationList: React.FC<OrganizationListProps> = ({
  organizationList,
  organizationClickHandler,
}) => {
  const { palette } = useTheme();
  return (
    <>
      <Container disableGutters={true} sx={{ paddingX: '10px' }}>
        <VerticalScrollableContainer disableGutters maxHeight="12.5rem">
          <MenuList>
            {organizationList.map((organization) => (
              <HoverableItem
                key={organization.id}
                onClick={() => organizationClickHandler(organization)}
                direction="row"
                alignItems="center"
              >
                <ListItemAvatar>
                  <ImageAvatar
                    src={organization.logoSrc}
                    organizationName={organization.name}
                    size={'xxsmall'}
                  />
                </ListItemAvatar>
                <OrganizationNameContainer>
                  <Container maxWidth={undefined} disableGutters={true}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color={palette.DropdownList.Label}
                    >
                      {organization.name}
                    </Typography>
                    {organization.isAdmin && (
                      <CustomBadge
                        color={palette.Badge.CountDefault}
                        backgroundColor={palette.Badge.BgDefault}
                        text="Admin"
                      />
                    )}
                    {!organization.isOnboarded && (
                      <CustomBadge
                        color={colorPalette.ORANGE400}
                        backgroundColor={colorPalette.ORANGE50}
                        text="New Invite"
                      />
                    )}
                  </Container>
                  {organization.isCurrentOrganization && (
                    <CheckCircleIcon
                      sx={{ marginLeft: 10 }}
                      fontSize="small"
                      color="secondary"
                    />
                  )}
                </OrganizationNameContainer>
              </HoverableItem>
            ))}
          </MenuList>
        </VerticalScrollableContainer>
      </Container>
    </>
  );
};

export default OrganizationList;
