// We could make this a bit DRY-er with computed string enums,
// but it won't be available until TypeScript's version 5 release.

enum AppRoutesEnum {
  ACCOUNTS = '/accounts',
  ACCOUNTS_ROOT = '/accounts/*',
  ACCOUNTS_LOGIN = '/accounts/login',
  ACCOUNTS_SIGNUP = '/accounts/signup',
  ACCOUNTS_FORGOT_PASSWORD = '/accounts/forgot-password',
  ACCOUNTS_INCOMPLETE_SETUP = '/accounts/incomplete-setup',
  ACCOUNTS_NO_PROFILE = '/accounts/no-profile',
  ACCOUNTS_VERIFY_EMAIL = '/accounts/verify-email',
  ACCOUNTS_CHECK_EMAIL = '/accounts/check-email',
  ACCOUNTS_RESET_PASSWORD = '/accounts/reset-password',
  ACCOUNTS_VERIFICATION = '/accounts/verification',

  CREATE_ORGANIZATION = '/create-organization',
  CREATE_PROFILE = '/create-profile',
  CREATE_COMPANY = '/create-company',

  ORGANIZATION_ROOT = '/:organizationName/*',

  HOME = '/:organizationName/home',
  HOME_FOLLOWING = '/:organizationName/home/following',
  HOME_ALL = '/:organizationName/home/all',
  HOME_FEED = '/:organizationName/feed/:feedId',

  EXPLORE = '/:organizationName/explore',
  EXPLORE_ROOT = '/:organizationName/explore/*',
  EXPLORE_PEOPLE_ROOT = '/:organizationName/explore/people/*',
  EXPLORE_PEOPLE = '/:organizationName/explore/people/card',
  EXPLORE_PEOPLE_TABLE = '/:organizationName/explore/people/table',
  EXPLORE_OPPORTUNITIES = '/:organizationName/explore/opportunities',
  EXPLORE_ALUMNI = '/:organizationName/explore/alumni',
  EXPLORE_BEHAVIORS = '/:organizationName/explore/behaviors',
  EXPLORE_PROJECTS = '/:organizationName/explore/projects',
  EXPLORE_PRAISE = '/:organizationName/explore/praise',
  EXPLORE_REVIEWS = '/:organizationName/explore/reviews',
  EXPLORE_COMPANY_UPDATES = '/:organizationName/explore/company-updates',
  EXPLORE_EVENTS = '/:organizationName/explore/events/:eventFilterType/:selectedTab/:month/:year',
  EXPLORE_QUICK_LINKS = '/:organizationName/explore/quick-links',
  EXPLORE_WIN_LISTS = '/:organizationName/explore/win-lists',

  ORG_CHART_ROOT = '/:organizationName/org-chart/*',
  ORG_CHART_PROFILE = '/:organizationName/org-chart/PROFILE/:id*',
  ORG_CHART_PROFILE_WITH_OVERVIEW = '/:organizationName/org-chart/PROFILE/:id/:profileId',
  ORG_CHART_STATIC_DATA = '/:organizationName/org-chart/static-data/:type/:id',
  ORG_CHART_STATIC_DATA_WITH_OVERVIEW = '/:organizationName/org-chart/static-data/:type/:id/:staticDataType/:staticDataId',

  PROFILE = '/:organizationName/profile/:profileId',
  PROFILE_ROOT = '/:organizationName/profile/:profileId/*',
  PROFILE_SKILLS = '/:organizationName/profile/:profileId/skills',
  PROFILE_ASPIRATIONS = '/:organizationName/profile/:profileId/aspirations',
  PROFILE_EDIT = '/:organizationName/profile/:profileId/edit',
  PROFILE_BEHAVIORS_REPORT = '/:organizationName/profile/:profileId/behaviors-report',
  PROFILE_ARCHETYPES = '/:organizationName/profile/:profileId/archetypes',
  PROFILE_ARCHETYPES_ROOT = '/:organizationName/profile/:profileId/archetypes/*',
  PROFILE_ARCHETYPES_ASSESSMENT = '/:organizationName/profile/:profileId/archetypes/assessment',
  PROFILE_ACTIVITY = '/:organizationName/profile/:profileId/activity',
  PROFILE_EXPERIENCE = '/:organizationName/profile/:profileId/experience',
  PROFILE_WIN_LIST = '/:organizationName/profile/:profileId/win-list',
  PROFILE_PRAISE = '/:organizationName/profile/:profileId/praise',
  PROFILE_BOB = '/:organizationName/profile/:profileId/best-of',

  BEHAVIORS_REPORT = '/:organizationName/behaviors/reports',
  BEHAVIORS_ASSESSMENT = '/:organizationName/behaviors/assessment',

  ADMIN = '/:organizationName/admin',
  ADMIN_ROOT = '/:organizationName/admin/*',
  ADMIN_INVITE_USERS = '/:organizationName/admin/invite-users',
  ADMIN_MANAGE_ORGANIZATION = '/:organizationName/admin/manage-organization',
  ADMIN_MANAGE_USERS = '/:organizationName/admin/manage-users',
  ADMIN_MANAGE_STATIC_DATA_ROOT = '/:organizationName/admin/static-data',
  ADMIN_MANAGE_STATIC_DATA_TYPE = '/:organizationName/admin/static-data/:staticDataType',

  REVIEW = '/:organizationName/review/:reviewId',

  SWITCH_ORGANIZATION = '/switch-organization',

  STATIC_DATA_SUMMARY = '/:organizationName/static-data/:staticDataType/:staticDataId/summary',
  STATIC_DATA_EVENTS = '/:organizationName/static-data/:staticDataType/:staticDataId/events',
  STATIC_DATA_COMPANY_UPDATES = '/:organizationName/static-data/:staticDataType/:staticDataId/company-update',
  STATIC_DATA_OPPORTUNITIES = '/:organizationName/static-data/:staticDataType/:staticDataId/opportunities',
  STATIC_DATA_WINS = '/:organizationName/static-data/:staticDataType/:staticDataId/wins',
  STATIC_DATA_PRAISE = '/:organizationName/static-data/:staticDataType/:staticDataId/praise',
  STATIC_DATA_VIDEOS = '/:organizationName/static-data/:staticDataType/:staticDataId/videos',

  COMPANY_OVERVIEW_ROOT = '/:organizationName/overview',

  COMPANY_OVERVIEW_SUMMARY = '/:organizationName/overview/summary',
  COMPANY_OVERVIEW_EVENTS = '/:organizationName/overview/events',
  COMPANY_OVERVIEW_COMPANY_UPDATES = '/:organizationName/overview/company-update',
  COMPANY_OVERVIEW_OPPORTUNITIES = '/:organizationName/overview/opportunities',
  COMPANY_OVERVIEW_WINS = '/:organizationName/overview/wins',
  COMPANY_OVERVIEW_PRAISE = '/:organizationName/overview/praise',
  COMPANY_OVERVIEW_VIDEOS = '/:organizationName/overview/videos',

  MANAGE_NOTIFICATIONS = '/:organizationName/notifications/following',
  MANAGE_EMAIL_PREFERENCES = '/:organizationName/notifications/email-preferences',
}

export default AppRoutesEnum;
