import CXAddButton from 'common/components/cx-add-button/cx-add-button.component';
import { useDrawer, useSession } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

export const OpportunityCreateActionButton: React.FC = () => {
  const { profile } = useSession();
  const isAdmin = profile?.isAdmin();
  const { openDrawer } = useDrawer();

  const handleClickAddNewEvent = () => {
    openDrawer(DrawerIdEnum.OPPORTUNITY, {
      opportunity: {},
    });
  };

  return (
    <CXAddButton
      label="Add Opportunity"
      onClick={handleClickAddNewEvent}
      isVisible={isAdmin}
    />
  );
};
