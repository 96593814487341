import { map, pick } from 'lodash';

import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createPraiseApi } from '../praise.service';
import { PraiseEventData } from '../types';

export const useCreatePraise = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter<PraiseEventData>('PraiseAdded');

  return useMutation(createPraiseApi, {
    showSuccessSnackbar: true,
    transformInput: (praiseForm: any) => {
      const praise = pick(praiseForm, [
        'receiver',
        'headline',
        'description',
        'skills',
        'relatedProject',
      ]);
      return {
        receiverId: praise.receiver?.[0]?.id,
        headline: praise.headline,
        description: praise.description,
        skillIds: map(praise.skills, (skill) => skill?.id),
        relatedProjectId: praise.relatedProject?.id,
      };
    },
    onSuccess: async ({ data }) => {
      emitWithSettledAfter(5000, { data });
    },
    ...options,
  });
};
