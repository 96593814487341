import { orderBy } from 'lodash';

import { Skill } from 'features/skills';
import { getWorkLocationWithOffice } from 'features/static-data';
import { SearchResponse, SearchResult } from 'features/search';

export class SearchModel {
  searchResult: SearchResult[];

  constructor(searchResponse: SearchResponse[]) {
    this.searchResult = this.getTransformedSearchResult(searchResponse);
  }

  getTransformedSearchResult(searchResponse: SearchResponse[]) {
    return (
      searchResponse &&
      searchResponse.map((profile: SearchResponse) => {
        const organizationProfile = { ...profile.organizationProfile.data };

        if (organizationProfile?.workLocation) {
          organizationProfile.workLocation = getWorkLocationWithOffice(
            organizationProfile?.workLocation,
          );
        }

        const endorsedSkillIds = new Set();
        const endorsedSkills = profile.endorsedSkills.map((endorsedSkill) => {
          endorsedSkillIds.add(endorsedSkill.skill.id);
          return {
            ...endorsedSkill.skill,
            score: endorsedSkill.score,
            isEndorsedByLoggedInUser: endorsedSkill.isEndorsedByLoggedInUser,
            isAspiredSkill: endorsedSkill.isAspiredSkill,
          };
        });
        const aspiredSkills = profile.aspiredSkills
          .filter((skill) => !endorsedSkillIds.has(skill.id))
          .map((skill) => ({
            ...skill,
            isAspiredSkill: true,
          }));
        const skills = [...aspiredSkills, ...endorsedSkills];

        return {
          photo: profile.photo,
          fullName: profile.fullName,
          firstName: profile.firstName,
          lastName: profile.lastName,
          pronouns: profile?.pronouns,
          profileId: profile.id,
          organizationProfile,
          highlight: profile?.highlight,
          skills: this.getSortedSkills(skills),
        };
      })
    );
  }

  getSortedSkills(skills: Skill[]) {
    return orderBy(skills, ['isMatch', 'name'], ['desc', 'asc']);
  }

  getSearchResult() {
    return this.searchResult;
  }
}
