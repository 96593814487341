import React from 'react';

import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';
import QuickLinkMenuItems from 'common/components/menu-items/quick-link-menu-items.component';
import ExploreMenuItems from 'common/components/menu-items/explore-menu-items.component';
import { Divider } from 'common/components/material';
import { useDrawer } from 'common/hooks';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import MenuDrawerHeader from './menu-drawer-header.component';

const MenuDrawer: React.FC = () => {
  const { closeDrawer } = useDrawer();

  return (
    <>
      <MenuDrawerHeader label="Menu" onClick={() => closeDrawer(DrawerIdEnum.MENU)} />
      <VerticalScrollableContainer disableGutters>
        <div onClick={() => closeDrawer(DrawerIdEnum.MENU)}>
          <ExploreMenuItems />
          <Divider />
          <QuickLinkMenuItems />
        </div>
      </VerticalScrollableContainer>
    </>
  );
};

export default MenuDrawer;
