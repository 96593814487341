import { isNil } from 'lodash';

import { useSession } from 'common/hooks';
import { GetStaticDataDetailsParams, useGetStaticDataDetails } from 'features/static-data';

export const useGetOnBehalfOfValue = ({
  isComingFromGlobalCreateButton,
  onBehalfOfPage,
}: {
  isComingFromGlobalCreateButton: boolean;
  onBehalfOfPage?: { pageId: string; type: string };
}) => {
  const { profile, currentOrganization } = useSession();
  const company = currentOrganization();
  const isContentAdmin = profile?.isContentAdmin();
  const isAdmin = profile?.isAdmin();
  const hasOnBehalfOfPage = !isNil(onBehalfOfPage);
  const { data: page } = useGetStaticDataDetails(
    {
      id: onBehalfOfPage?.pageId,
      type: onBehalfOfPage?.type,
    } as GetStaticDataDetailsParams,
    { enabled: hasOnBehalfOfPage },
  );

  if (
    (isComingFromGlobalCreateButton || isNil(onBehalfOfPage)) &&
    (isContentAdmin || isAdmin) &&
    !isNil(company)
  ) {
    return {
      id: company.id,
      name: company.name,
      displayName: company.name,
      completeName: company.name,
      icon: company.logo,
      type: 'COMPANY',
    };
  } else if (!isNil(onBehalfOfPage) && !isNil(page)) {
    return {
      id: page.id,
      name: page.name,
      displayName: page.name,
      completeName: page.fullName,
      icon: page.photo,
      type: onBehalfOfPage.type,
    };
  }
};
