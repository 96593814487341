import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { Drawer, Popover, Typography } from 'common/components/material';
import SwitchOrganizationModal from 'common/components/modals/SwitchOrganizationModal';
import { OrganizationRolesEnum } from 'common/enum/Organization.enum';
import { usePopoverState, useSession, useView } from 'common/hooks';
import { Organization } from 'common/types/Organization.type';
import { useState } from 'react';
import OrganizationList from './OrganizationList';

const StyledOrganizationDropdownContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  minWidth: 0,
  borderRadius: '0.375rem',
  padding: '1px 1px 1px 5px',
  backgroundColor: theme.palette.Tabs.Primary.BgDefault,
  color: theme.palette.LinkText.Default,
  '&:hover': {
    color: theme.palette.LinkText.Active,
  },
  '&:active': {
    color: theme.palette.LinkText.Active,
    backgroundColor: theme.palette.Tabs.Primary.BgActive,
  },
}));

const OrganizationDropdown = () => {
  const { isDesktopView } = useView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetOrganization, setTargetOrganization] = useState<Organization>();
  const { profile } = useSession();
  const organization = profile?.getCurrentOrganization();
  const organizationList =
    profile?.organizationProfiles?.map((orgProfile) => {
      const company = orgProfile.company;
      return {
        ...company,
        isOnboarded: orgProfile.isOnboarded,
        isAdmin: orgProfile.role === OrganizationRolesEnum.ADMIN,
      };
    }) || [];
  const { anchorElement, openPopover, closePopover } = usePopoverState();
  const open = !!anchorElement;
  const handleOrganizationChange = (org: Organization) => {
    setIsModalOpen(true);
    setTargetOrganization(org);
    closePopover();
  };

  if (organizationList.length < 2) {
    return null;
  }

  const organizationListContainerElement = isDesktopView ? (
    <Popover
      open={open}
      anchorEl={anchorElement}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <OrganizationList
        organizationList={organizationList}
        organizationClickHandler={handleOrganizationChange}
      />
    </Popover>
  ) : (
    <Drawer showHeader={false} anchor="bottom" open={open} toggleDrawer={closePopover}>
      <OrganizationList
        organizationList={organizationList}
        organizationClickHandler={handleOrganizationChange}
      />
    </Drawer>
  );

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <StyledOrganizationDropdownContainer onClick={openPopover}>
        <Typography
          variant="body4"
          fontWeight={600}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {organization?.name}
        </Typography>
        {open ? (
          <KeyboardArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </StyledOrganizationDropdownContainer>
      {organizationListContainerElement}
      {isModalOpen && (
        <SwitchOrganizationModal
          open={isModalOpen}
          organization={targetOrganization}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default OrganizationDropdown;
