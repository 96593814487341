import { Stack } from 'common/components/material';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { useSession } from 'common/hooks';
import { MobileHeaderContainer, MobileHeaderText } from '../styles/explore.styles';
import FilterTextButton from './FilterTextButton';
import { ProfilesExportButton } from './profiles-export-button.component';

export const MobileExploreHeaderComponent: React.FC<{
  contentType: ExploreTabEnum;
  onFiltersClick: () => void;
  selectedFiltersCount?: number;
}> = ({ contentType, onFiltersClick, selectedFiltersCount }) => {
  const { profile } = useSession();
  const isAdmin = profile?.isAdmin();

  return (
    <MobileHeaderContainer>
      <MobileHeaderText>Search For</MobileHeaderText>
      <Stack direction="row" spacing={1} flex="1 1 0" justifyContent="end">
        {contentType === ExploreTabEnum.PEOPLE && isAdmin && <ProfilesExportButton />}
        <FilterTextButton
          filterCount={selectedFiltersCount}
          highlightText={true}
          onClick={onFiltersClick}
        />
      </Stack>
    </MobileHeaderContainer>
  );
};
